import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'

import ROUTES from "utils/routes";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Footer from 'components/footer/Footer';

import './DatasetsPage.scss';

const IMG_STYLE = {
  "border-radius": "4px",
  "padding": "5px",
  "width": "150px", 
}

const DatasetsPage = () => {
  return (
    <div>
      <Container className="dataset">
        <div className="title"><div className="mainTitle">Datasets</div></div>

        <Row>
          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on report level data."
            img={<StaticImage src="./images/ReportLevel.jpg" alt="report level" imgStyle={IMG_STYLE}/>}
            route="/report_level"
            title="Report Level Data"
          />

          <Col lg={2}>
          <br/>
          <br/>
          <br/>
          </Col>

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on client level data."
            img={<StaticImage src="./images/ClientLevel.jpg" alt="client level" imgStyle={IMG_STYLE}/>}
            route="/client_level"
            title="Client Level Data"
          />
        </Row>

        <Row>
          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on bill level data."
            img={<StaticImage src="./images/BillLevel.jpg" alt="bill level" imgStyle={IMG_STYLE}/>}
            route="/bill_level"
            title="Bill Level Data"
          />

          <Col lg={2}>
          <br/>
          <br/>
          <br/>
          </Col>

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on issue level data."
            img={<StaticImage src="./images/IssueLevel.jpg" alt="issue level" imgStyle={IMG_STYLE}/>}
            route="/issue_level"
            title="Issue Level Data"
          />
        </Row>

        <Row>
          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on network data."
            img={<StaticImage src="./images/IssueLevel.jpg" alt="network level" imgStyle={IMG_STYLE}/>}
            route="/network_data"
            title="Network Data"
          />

          <Col lg={2}>
          <br/>
          <br/>
          <br/>
          </Col>

          <ClickableDatasetCard
            description="This dataset contains information on lobbying activites on text data."
            img={<StaticImage src="./images/TextData.jpg" alt="text level" imgStyle={IMG_STYLE}/>}
            route="/text_data"
            title="Text Data"
          />
        </Row>

        <Row>
          <ClickableDatasetCard
            description="To fill the gap in detailed and accurate data on political lobbying, we built a highly granular lobbyist level dataset."
            img={<StaticImage src="./images/RevolvingDoor.jpg" alt="revolving door" imgStyle={IMG_STYLE}/>}
            route="/lobbyist_level"
            title="Lobbyist Level"
          />

          <Col lg={2}>
          <br/>
          <br/>
          <br/>
          </Col>

          
        </Row>

        <Row>
          <Col>
            <center><Link to={ROUTES.DATA_DOWNLOAD}><Button className="roundTealButton">Back</Button></Link></center>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  )
}

export default DatasetsPage



type ClickableDatasetCardProps = {
  description: string,
  img: React.FC,
  route: string,
  title: string,
}

const ClickableDatasetCard = (props:ClickableDatasetCardProps) => {
  return (
    <Col sm={12} md={6} lg={5}>
      <Link to={ROUTES.DATASETS+props.route} className="link">
        <div className="box card">
          <center>
            <div>
              {props.img}
              <div className="title"><div>{props.title}</div></div>
              <p>{props.description}</p>
            </div>
            <Button className="roundTealButton">Additional Dataset Info</Button>
          </center>
        </div>
      </Link>
    </Col>
  )
}

ClickableDatasetCard.propTypes = {
  description: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  route: PropTypes.string,
  title: PropTypes.string.isRequired,
}
